/**
 * admin layout css style
 * create by phachon
 */

html,body,#root{
    width: 100%;
    height: 100%;
}
.ant-layout{
    height: 100%;
}

.admin-header {
    display: flex;
    box-shadow: 0 4px 5px rgba(0,21,41,.08);
    padding: 0;
    width: 100%;
    height: 48px;
    line-height: 48px;
}

.admin-header-logo {
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    transition: all .3s;
    padding: 0 8px;
    width: 208px;
    height: 48px;
    line-height: 48px;
    /*border-bottom: #000b17 solid 1px;*/
}

.admin-header-logo span {
    margin: 0 0 0 8px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    vertical-align: middle;
    animation: fade-in;
    animation-duration: .3s;
}

.admin-header-logo img {
    height: 38px;
}

.admin-header-nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.admin-header-right {
    display: flex;
    padding: 0 12px;
    transition: all .3s;
}

.admin-header-action {
    cursor: pointer;
    padding: 0 12px;
    /*margin-right: 6px;*/
    color: #fff;
    transition: all .3s;
}

.admin-header-action a {
    color: #fff;
}

.admin-header-action:hover {
    background: #192336;
}

.admin-header-left .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #192336;
}

.admin-header-left .ant-menu.ant-menu-dark .ant-menu-item-active {
    background-color: #192336;
}

.admin-sidebar {
    box-shadow: 0 0 2px rgba(0,21,41,.35);
    transition: all 0.3s ease 0s;
    overflow-x: visible;
}

.admin-sidebar-menu {
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-layout-sider-trigger {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #ddd;
}

.admin-content {
    margin: 0 8px;
    /*padding: 8px 8px;*/
    width: auto;
    background: #fff;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    -moz-border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    overflow: auto;
}

.admin-breadcrumb {
    margin: 8px 8px 8px 8px;
    padding-left: 8px;
    background-color: #fff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    box-shadow: 0 2px 13px 0 rgba(228, 228, 228, 0.6);
}

.admin-breadcrumb-nav {
    line-height: 35px;
}

.admin-breadcrumb-nav a {
    line-height: 35px;
}

.ant-breadcrumb-separator {
    margin: 0 4px;
}

.admin-footer {
    text-align: center;
    padding: 14px 50px;
}