/**
 * common css style
 * create by phachon
 */
@import '~antd/dist/antd.css';

.panel {
    padding-top: 8px;
}

.panel-body {
    padding: 8px 16px 8px 16px;
}

.hide {
    display: none;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 10px 10px !important;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

/* margin-right */
.mgr10 {
    margin-right: 10px;
}

.mgr14 {
    margin-right: 14px;
}

.mgr15 {
    margin-right: 15px;
}

.mgr20 {
    margin-right: 20px;
}

.mgr0 {
    margin-right: 0;
}

/* margin-top */
.mgt15 {
    margin-top: 15px;
}

.mgt18 {
    margin-top: 18px;
}

.mgt20 {
    margin-top: 20px;
}

/* padding */
.pdr0 {
    padding-right: 0
}

.pdr10 {
    padding-right: 10px
}

.pdr20 {
    padding-right: 20px
}

.pdr30 {
    padding-right: 30px
}

.pdr40 {
    padding-right: 40px
}

/* padding */
.pdt0 {
    padding-top: 0
}

.pdt10 {
    padding-top: 10px
}

.pdt20 {
    padding-top: 20px
}

.pdt24 {
    padding-top: 24px
}

.pdt30 {
    padding-top: 30px
}

.pdt40 {
    padding-top: 40px
}

/* width 按百分比 */
.w5p {
    width: 5%;
}

.w6p {
    width: 6%;
}

.w7p {
    width: 7%;
}

.w8p {
    width: 8%;
}

.w9p {
    width: 9%;
}

.w10p {
    width: 10%;
}

.w11p {
    width: 11%;
}

.w12p {
    width: 12%;
}

.w13p {
    width: 13%;
}

.w14p {
    width: 14%;
}

.w15p {
    width: 15%;
}

.w16p {
    width: 16%;
}

.w17p {
    width: 17%;
}

.w20p {
    width: 20%;
}

.w21p {
    width: 21%;
}

.w22p {
    width: 22%;
}

.w23p {
    width: 23%;
}

.w24p {
    width: 24%;
}

.w25p {
    width: 25%;
}

.w30p {
    width: 30%;
}

.w35p {
    width: 35%;
}

.w40p {
    width: 40%;
}

.w45p {
    width: 45%;
}

.w50p {
    width: 50%;
}

.w55p {
    width: 55%;
}

.w60p {
    width: 60%;
}

.w65p {
    width: 65%;
}

.w70p {
    width: 70%;
}

.w75p {
    width: 75%;
}

.w80p {
    width: 80%;
}

.w85p {
    width: 85%;
}

.w90p {
    width: 90%;
}

.w95p {
    width: 95%;
}

.w100p {
    width: 100%;
}

/* width 按像素 */
.w10 {
    width: 10px;
}

.w20 {
    width: 20px;
}

.w30 {
    width: 30px;
}

.w40 {
    width: 40px;
}

.w50 {
    width: 50px;
}

.w100 {
    width: 100px;
}

.w130 {
    width: 130px;
}

.w150 {
    width: 150px;
}

.w160 {
    width: 160px;
}

.w170 {
    width: 170px;
}

.w180 {
    width: 180px;
}

.w190 {
    width: 190px;
}

.w200 {
    width: 200px;
}

.w240 {
    width: 240px;
}

.w260 {
    width: 260px;
}

.w280 {
    width: 280px;
}

.w300 {
    width: 300px;
}

.w400 {
    width: 400px;
}

.w500 {
    width: 500px;
}

.w600 {
    width: 600px;
}

.w700 {
    width: 700px;
}

.w800 {
    width: 800px;
}

/* text font size */
.text-font-9 {
    font-size: 9px
}

.text-font-10 {
    font-size: 10px
}

.text-font-11 {
    font-size: 11px
}

.text-font-12 {
    font-size: 12px
}

.text-font-13 {
    font-size: 13px
}

.text-font-14 {
    font-size: 14px
}

.text-font-15 {
    font-size: 15px
}

.text-font-16 {
    font-size: 16px
}

.text-font-17 {
    font-size: 17px
}

.text-font-18 {
    font-size: 18px
}

.text-font-19 {
    font-size: 19px
}

.text-font-20 {
    font-size: 20px
}

.text-font-21 {
    font-size: 21px
}

.button-link {
    height: 0px;
    padding: 0px 2px;
}

.button-text {
    margin-left: 4px !important;
}



.mensaCSS .ant-picker-calendar-mode-switch {
    display: none;
  }

.mensaCSS .ant-picker-calendar-date-value {
    text-align: left;
}


.mensaCSS .ant-picker-panel .ant-picker-cell:not(.ant-picker-cell-in-view){
    visibility: hidden
}

.mensaCSS .ant-picker-cell:has(ul.events) > .ant-picker-calendar-date>.ant-picker-calendar-date-value
{
    border-radius: 3;
    width: 30px;
    background: #bfdefb;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    font: 120% Arial, sans-serif;
  }


div.ant-picker-calendar-date-content {
    overflow: hidden;
    overflow-y: hidden !important;
}